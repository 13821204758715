import { RuumEnvironment } from '.';

export const environment: RuumEnvironment = {
    production: true,
    BOX_APP_ID: 'ysplq9ve4rw9hoj4gon8dfdhgobrcums',
    APP_URL: 'https://open.ruumapp.com',
    MAIL: 'hello@ruumapp.com',
    AUTH_SERVICE_URL: 'https://auth.ruumapp.com/api',
    EMAIL_SERVICE_URL: 'https://email.ruumapp.com',
    PROJECT_SERVICE_URL: 'https://project.ruumapp.com',
    READ_MODEL_URL: 'https://read-model.ruumapp.com',
    TRACKING_URL: 'https://tracking.ruumapp.com',
    MS_APP_ID: '9536e7fc-3419-4657-a59e-3796b4902a58',
    MS_APP_REDIRECT_URI: 'https://open.ruumapp.com/auth/office365',
    WEBSOCKET_URL: 'https://websockets.ruumapp.com',
    GDRIVE_APP_ID: '605589597583',
    GDRIVE_API_KEY: 'AIzaSyCyPcjZCnvtc6nnOF5NDcn_zKm6TRCzFbc',
    GDRIVE_CLIENT_ID: '605589597583-bggr5opcgqfdbombnlhlda2gkjbnvh6l.apps.googleusercontent.com',
    GRECAPTCHA_SITE_KEY: '6LdCldgUAAAAAEy-rsZYZ8Nyw10MP8gGkBv8CeHi',
    GIT_COMMIT: process.env.GIT_COMMIT,
    // BEGIN Paywall related settings
    PAYWALL_UPGRADE_URL: 'https://help.sap.com/viewer/product/RUUM/Latest/en-US',
    PAYWALL_TRIAL_STATUSBAR_ENABLED: false,
};
